import { SearchIcon } from '@chakra-ui/icons';
import { Box, ChakraProps, HStack, Input } from '@chakra-ui/react';
import cn from 'clsx';
import { debounce } from 'lodash';
import React, {
  ChangeEvent,
  FC,
  KeyboardEventHandler,
  useCallback,
  useMemo
} from 'react';

import s from './Search.module.scss';

interface Props {
  className?: string;
  onSearch: (val: string) => void;
  placeholder?: string;
  inputProps?: ChakraProps;
}

export const Search: FC<Props> = ({
  className,
  onSearch,
  placeholder = 'Search',
  inputProps
}) => {
  const throttledChangeHandler = useMemo(() => {
    const changeHandler = async (event: ChangeEvent<HTMLInputElement>) => {
      const value = event?.target.value;

      onSearch(value?.trim());
    };

    return debounce(changeHandler, 700);
  }, [onSearch]);

  const handleSearchKeyUp = useCallback<KeyboardEventHandler>(
    e => {
      if (e.key === 'Enter') {
        throttledChangeHandler.flush();
      } else if (e.key === 'Esc') {
        throttledChangeHandler.cancel();
      }
    },
    [throttledChangeHandler]
  );

  return (
    <Box className={cn(s.root, className)} maxHeight="48px">
      <HStack className={s.wrapper}>
        <Box className={s.iconWrapper}>
          <SearchIcon w="20px" h="20px" />
        </Box>
        <Input
          variant="filled"
          fontFamily="p"
          borderRadius="8px"
          bg="neutral.700"
          fontSize="xl"
          p="6px 12px"
          border="none"
          color="neutral.200"
          size="lg"
          gridArea="input"
          _focus={{
            outline: 'none'
          }}
          // _hover={{ bg: 'white' }}
          type="search"
          onKeyUp={handleSearchKeyUp}
          onChange={throttledChangeHandler}
          _placeholder={{ color: 'neutral.400' }}
          placeholder={placeholder}
          {...inputProps}
        />
      </HStack>
    </Box>
  );
};
